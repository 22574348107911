body {
  font-family: "Quicksand", sans-serif;
}
.App {
  text-align: center;
  font-family: "Quicksand", sans-serif;
}

.App-logo {
  height: 60vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #ffffff;
}

.App-link {
  color: #09d3ac;
}
a:link,
a:visited {
  color: #0d84a8;
}

div.MuiPaper-root.MuiExpansionPanel-root:nth-child(1n):hover:not(.Mui-expanded) {
  background: #beeffc;
}
div.MuiPaper-root.MuiExpansionPanel-root:nth-child(2n):hover:not(.Mui-expanded) {
  background: #c4cffc;
}
div.MuiPaper-root.MuiExpansionPanel-root:nth-child(3n):hover:not(.Mui-expanded) {
  background: #ffecbe;
}
div.MuiPaper-root.MuiExpansionPanel-root:nth-child(4n):hover:not(.Mui-expanded) {
  background: #ffdfbe;
}
.fa-facebook {
  color: #3b5998;
}
.fa-youtube {
  color: #c4302b;
}
.fa-twitter {
  color: #1da1f2;
}
.fa-kickstarter {
  color: #87c442;
}
.fa-patreon {
  color: #f96854;
}
.fa-heart {
  color: purple;
}
a .fab:hover,
a:hover {
  color: #0b5c9b;
}
.fa-trophy.gold {
  color: gold;
}
.fa-trophy.silver {
  color: silver;
}
.fa-trophy.bronze {
  color: rgb(159, 124, 30);
}
ul.credits {
  list-style: none;
  text-align: left;
  padding: 0;
}
ul.credits li {
  font-size: 16px;
  margin-bottom: 5px;
}
section {
  padding: 1em;
}
nav,
footer {
  background: #282c34;
}
p {
  font-size: 1.25rem;
}

@media (max-width: 650px) {
  .MuiExpansionPanelDetails-root {
    display: inline !important;
  }
  .makeStyles-helper-12 {
    border-left: none !important;
  }
}

.swing {
  animation: swing ease-in-out 1s infinite alternate;
  transform-origin: center -20px;
  float: left;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
}
.swing img {
  border: 5px solid #f8f8f8;
  display: block;
}
.swing:after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border: 1px solid #999;
  top: -10px;
  left: 50%;
  z-index: 0;
  border-bottom: none;
  border-right: none;
  transform: rotate(45deg);
}
/* nail */
.swing:before {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  top: -14px;
  left: 54%;
  z-index: 5;
  border-radius: 50% 50%;
  background: #000;
}

@keyframes swing {
  0% {
    transform: rotate(3deg);
  }
  100% {
    transform: rotate(-3deg);
  }
}

div {
  animation: fadein 2s;
  -moz-animation: fadein 2s; /* Firefox */
  -webkit-animation: fadein 2s; /* Safari and Chrome */
  -o-animation: fadein 2s; /* Opera */
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadein {
  /* Firefox */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadein {
  /* Safari and Chrome */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fadein {
  /* Opera */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
